<script>
import LoadingVue from '@/components/ui/Loading.vue'
import sanitizer from '@/util/htmlSanitizer'

import { getLabPjt } from '@/api/platform/community.js'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  data() {
    return {
      pageId: this.$route.params.id,
      listUrl: this.$route.path.slice(0, this.$route.path.lastIndexOf('/')),
      isLoading: false,
      loadedData: null
    }
  },
  components: { LoadingVue },
  methods: {
    sanitizer,
    LanguageConverter,
    numberFormat(num) {
      return new Intl.NumberFormat().format(num)
    },
    async getData() {
      this.isLoading = true
      const data = await getLabPjt(this.pageId)

      if (data.projectPeriodStart !== null && data.projectPeriodEnd) {
        data.projectPeriodStart = this.$getDate(data.projectPeriodStart)
        data.projectPeriodEnd = this.$getDate(data.projectPeriodEnd)
      }
      this.isLoading = false
      return { ...data, projectStatusEn: data.projectStatus === '진행중' ? 'In Progress' : 'Wrapped Up' }
    },
    goList() {
      this.$router.push(this.listUrl)
    }
  },
  async mounted() {
    this.loadedData = await this.getData()
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="wf__section" v-if="loadedData">
    <div class="wf__title-wrapper">
      <h2 class="wf__title">프로젝트</h2>
      <div class="divider"></div>

      <div class="wf__board-title-wrapper">
        <h3 class="wf__board-title">{{ LanguageConverter({ en: loadedData.projectTitleEn, kr: loadedData.projectTitleKr }) }}</h3>

        <router-link class="wf__author" :to="`/lab/${loadedData.labNo}`">{{ loadedData.labName }} 바로가기</router-link>

        <ul class="wf__board-desc">
          <li>{{ $getDate(loadedData.createdAt) }}</li>
          <li>|</li>
          <li>조회 {{ numberFormat(loadedData.projectViewCount) }}</li>
        </ul>
      </div>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>{{ LanguageConverter({ en: 'Manage Agency', kr: '과제수행기관' }) }}</th>
            <td>{{ loadedData.projectManageAgency }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Project Manager', kr: '책임연구자' }) }}</th>
            <td>{{ loadedData.projectManager }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Ministry', kr: '부처명' }) }}</th>
            <td>{{ loadedData.projectMinistry }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Keyword', kr: '키워드' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.projectKeywordEn, kr: loadedData.projectKeywordKr }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Abstract', kr: '초록' }) }}</th>
            <td v-html="sanitizer(loadedData.projectAbstract)"></td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Expected Results', kr: '기대효과' }) }}</th>
            <td>{{ loadedData.projectEffect }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Project Goal', kr: '목표' }) }}</th>
            <td v-html="sanitizer(loadedData.projectGoal)"></td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Progress Period', kr: '과제 기간' }) }}</th>
            <td>{{ $getDate(loadedData.projectPeriodStart) }} ~ {{ $getDate(loadedData.projectPeriodEnd) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>{{ LanguageConverter({ en: 'Extra Information', kr: '추가정보' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.projectAddTextEn, kr: loadedData.projectAddTextKr }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Progress', kr: '진행상황' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.projectStatusEn, kr: loadedData.projectStatus }) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="divider"></div>

    <div>
      <span class="wf__notice-list-btn" @click="goList">List</span>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__section {
  margin: 4rem 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 0 0.5em;

  font-size: var(--wf-text-16);
}

.wf__title-wrapper {
  text-align: center;
}
.wf__board-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  font-size: 16px;
  font-weight: 300;
}
.wf__title {
  font-size: var(--wf-text-24);
  font-weight: bold;
  text-align: left;
}
.wf__board-title {
  word-break: normal;
  font-size: 38px;
  font-weight: 700;
  margin-top: 46px;
  max-width: 28em;
}
.wf__board-desc {
  display: flex;
  justify-content: center;
  gap: 0.5em;
}
.wf__author {
  color: var(--wf-primary-color);
  font-weight: 400;
}
.divider {
  height: 2px;
  background-color: var(--wf-gray-color);

  margin: 1rem 0;
}

.adminTable,
.adminTable table {
  min-width: 0;
  width: 100%;
  word-break: normal;
}
.adminTable th {
  text-align: center;
  font-size: 16px;
}
.adminTable td {
  padding: 1em 2em;
  font-size: 16px;
}

.wf__notice-list-btn {
  font-size: 1em;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #fff;
  background-color: var(--wf-primary-color);
  float: right;
  cursor: pointer;
  border-radius: 0.25em;
  padding: 0.35em 1em;
}
.wf__notice-list-btn:hover {
  opacity: 0.75;
}
</style>
